import React, { } from 'react';
import styled from 'styled-components';
import logo from '../../assets/img/sv-logo.png';
import phone from '../../assets/img/sv-phone.png';
import email from '../../assets/img/sv-email.png';
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SvFooter = ({ socs, buttonText, christmas, hideButton }: any) => <>
  <div style={{ background: '#0A2540', padding: '0 16px' }}>
    <NavbarContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <img style={{ width: '160px' }} src={logo}></img>
        </div>
        <div style={{ marginLeft: '32px' }}>
          <img src={email} style={{ width: '24px' }} /> <a href="mailto: info@savanoriaivaikams.lt">info@savanoriaivaikams.lt</a>
        </div>
        <div style={{ marginLeft: '32px' }}>
          <img src={phone} style={{ width: '24px' }} /><a href="tel: +37060242450"> +370 602 42 450</a>
        </div>
        <SocDivas>
          {socs?.facebook && christmas && <a href={socs.facebook} target="_blank" style={{ margin: '0 15px', fontSize: '21px' }}>
            <FontAwesomeIcon icon={faFacebookF} />
          </a>}
          {socs?.instagram && christmas && <a href={socs.facebook} target="_blank" style={{ margin: '0 15px', fontSize: '21px' }}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>}
          {socs?.linkedin && <a href={socs.linkedin} target="_blank" style={{ margin: '0 15px', fontSize: '21px' }}>
            <FontAwesomeIcon icon={faLinkedin} />
          </a>}
          {!hideButton && <Button onClick={() => buttonText ? window.open('https://niekienovaikai.lt/paremti') : navigate('/paremk')} style={{ marginLeft: 'auto', width: 'auto' }}>{buttonText || 'Paremk'} </Button>}
        </SocDivas>
      </div>
      <div style={{ display: 'flex', marginTop: '32px' }}>
        <div>VŠĮ "Savanoriai vaikams" (paramos gavėjo kodas: 305017992) <a href="https://drive.google.com/file/d/1VZQyOmlH9B-a9UbzgYFInRS2Uf8eZUHA/view?usp=sharing)" target="_blank" style={{ textDecoration: "underline !important", marginLeft: "0.5rem" }}>Projektai</a></div>
        <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
          <div className="super" onClick={() => window.open('https://drive.google.com/file/d/1ttyn4XDYVBCuhphMFctbVzfRykxtns84/view')}>Privatumo politika</div>
          <div className="super" onClick={() => window.open('https://drive.google.com/file/d/1lJNv_qqr3BnqfgJ-XVss8miK0d2Qmae2/view')}>Aukojimo taisyklės</div>
        </div>
      </div>
    </NavbarContainer>
  </div>
</>

export default SvFooter;

const Button = styled.button`
	text-transform: uppercase;
	border: none;
	min-height: 52px;
	border-radius: 12px;
	color: #fff;
  background: #0E558F;
	font-size: 16px;
	font-weight: 600;
	
	text-align: center; 

  letter-spacing: 0.03em;
  color: #FFFFFF;
  padding: 0 1.5rem;
`

const NavbarContainer = styled.div`
    padding: 64px 0;
    color: white; 
    max-width: 1160px;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 768px) {
      > div {
        flex-direction: column;
        gap: 20px;
        text-align: center;

      }
      button{
        margin: auto;
      }
      .super{
        margin: auto;
      }
      
    }
`

const SocDivas = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-left: initial !important;
    flex-direction: column;
  }
`