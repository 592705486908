import React from 'react';
import classnames from 'classnames';
import logo from '../../assets/img/sv-logo.png';
import * as styles from './Footer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import Button, { ButtonType } from '../shared/Button/Button';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { getData } from '../utils';

const number = '+370 602 42 450';

const newsLetter = (
  <>
    {/* <a href="javascript:;" onClick={() => (window as any)?.ml_webform_2528957('show')}>Naujienlaiškio prenumerata</a> */}
    <a target="_target" href="https://drive.google.com/file/d/1ttyn4XDYVBCuhphMFctbVzfRykxtns84/view">
      Privatumo politika
    </a>
    <a target="_target" href="https://drive.google.com/file/d/1lJNv_qqr3BnqfgJ-XVss8miK0d2Qmae2/view">
      Aukojimo taisyklės
    </a>
  </>
);

const renderPhone = (tel: any) => (
  <>
    <FontAwesomeIcon icon={faPhoneAlt} />
    {tel}
  </>
);

const renderEmail = (e: any) => (
  <>
    <FontAwesomeIcon icon={faEnvelope} />
    {e}
  </>
);

const Footer = () => {
  
  let data = useStaticQuery(query);
  data = getData(data, 'KONTAKTAI');


  return <div className={styles.footer} id="kontaktai">
    <div className="container">
      <div className={classnames('row', styles.insideFooter)}>
        <div className="col-xs-12 col-md-7 col-lg-8 pl-3">
          <div className={classnames(styles.leftContainer, 'row')}>
            <div className="col-md-12 col-lg-3">
              <img src={logo} />
            </div>

            <a className="col-md-12 col-lg-5" href="mailto:info@savanoriaivaikams.lt">
              {renderEmail(data?.elPastas)}
            </a>
            <a className="col-md-12 col-lg-4" href={`tel:${number}`}>
              {renderPhone(data?.telefonas)}
            </a>
            <p className={classnames(styles.companyName, 'col-12')}>
              VšĮ "Savanoriai vaikams" (paramos gavėjo kodas: 305017992)
            </p>
            <a className={styles.mailMobile} href="mailto:info@savanoriaivaikams.lt">
              {renderEmail(data?.elPastas)}
            </a>
            <a className={styles.phoneMobile} href={`tel:${number}`}>
              {renderPhone(data?.telefonas)}
            </a>
            <div className="pl-3">
              <Link to="/paremti">
                <Button
                  className="d-block d-md-none mb-2"
                  title="Paremk"
                  type={ButtonType.Primary}
                />
              </Link>
              <div className={classnames('d-block d-md-none', styles.newsletterContainer)}>
                {newsLetter}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-5 col-lg-4">
          <div className={styles.rightContainer}>
            <div className={classnames(styles.social, 'd-flex')}>
              {data?.facebook && <a href={data.facebook} target="_blank">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>}
              {data?.instagram && <a href={data.facebook} target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
              </a>}
              {data?.linkedin && <a href={data.linkedin} target="_blank">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>}
              <Link to="/paremti">
                <Button
                  className="d-none d-md-block"
                  title="Paremk"
                  type={ButtonType.Primary}
                />
              </Link>
            </div>
            <div className={classnames('d-none d-md-block', styles.newsletterContainer)}>
              {newsLetter}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
};


export const query = graphql`query {
  strapiDraugystes {
      KONTAKTAI {
          elPastas
          telefonas
          facebook
          instagram
          linkedin
      }
  }
}
`;

export default Footer;
